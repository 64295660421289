import { Link, Head } from '@inertiajs/react';

export default function Welcome(props) {
    return (
        <>
            <Head title="Welcome" />
            <div className='welcome'>
                {props.auth.user ? (
                    <div className='flex justify-end'>
                        <Link href={route('home')} className="mt-4 mr-4 bg-lime-500 hover:bg-lime-600 font-bold text-sm text-white border-2 py-1 px-4 rounded-full">
                            Home
                        </Link>
                    </div>
                ) : (
                    <>
                        <div className='flex justify-end'>
                            <Link href={route('login')} className="mt-4 mr-4 bg-lime-500 hover:bg-lime-600 font-bold text-sm text-white border-2 py-1 px-4 rounded-full">
                                Log in
                            </Link>
                        </div>
                        {/* <Link
                            href={route('register')}
                            className="ml-4 text-sm underline"
                        >
                            Register
                        </Link> */}
                    </>
                )}
            </div>
        </>
    );
}
